import { FormsFieldPreset, FormPlugin, FieldCollectionType } from '@wix/forms-common'
import { DEFAULT_PLACEHOLDER } from '../constants'
import { CUSTOM_FIELD, TEXT_CRM_TYPES } from '../../../../../../constants/crm-types-tags'
import { iconNames } from '../../icons/types'
import { DEFAULT_CATEGORIES } from '../../../../../../panels/manage-fields-panel/constants/manage-fields-constants'
import { TextInput } from '../../fields-by-comp-type/definitions/text-input'

export class GeneralText extends TextInput {
  public get fieldType(): FieldPreset {
    return FormsFieldPreset.GENERAL_TEXT
  }

  public get mainCrmTypes() {
    return TEXT_CRM_TYPES
  }

  protected get icon() {
    return iconNames.shortText
  }

  public get collectionFieldType() {
    return FieldCollectionType.TEXT
  }

  public get crmType() {
    return CUSTOM_FIELD
  }

  protected get renderConfig() {
    return {
      [FormPlugin.FORM_BUILDER]: {
        addFieldPanelData: { category: DEFAULT_CATEGORIES.text }
      }
    }
  }

  protected get data(): any {
    return {
      textType: 'text',
      placeholder: this.translate(DEFAULT_PLACEHOLDER),
      type: 'TextInput',
      value: '',
    }
  }

  protected get props(): any {
    return {
      type: 'TextInputProperties',
      required: false,
    }
  }

  public get base() {
    return GeneralText.prototype
  }
}
