import { IGeneralField } from '../../general-field'
import { FIELDS } from '../../../../../../constants/roles'
import { fieldManifest } from '../../field-utils'
import { baseDesignMappingValues } from '../../constants'
import { FIELD_COMPONENT_TYPES } from '@wix/forms-common'

export abstract class TextInput extends IGeneralField {

  protected get componentType() {
    return FIELD_COMPONENT_TYPES.TEXT_INPUT
  }

  public get role() {
    return FIELDS.ROLE_FIELD_TEXT
  }

  public get manifest() {
    return fieldManifest({ duplicatable: true })
  }

  public get designMapping() {
    return {
      [this.componentType]: baseDesignMappingValues
    }
  }

  public get layoutComponentProps() {
    return ['textAlignment', 'textPadding', 'labelPadding', 'labelMargin']
  }

  public get supportsLabel() {
    return true
  }

  public get supportedInResponsive() {
    return true
  }

  protected get skin() {
    return 'wysiwyg.viewer.skins.appinputs.AppsTextInputSkin'
  }

  protected get responsiveSkin() {
    return 'wysiwyg.viewer.skins.textinputs.ResponsiveTextInputSkin'
  }

  protected get layout() {
    return {
      width: 240,
      height: 42,
    }
  }

  protected get styleProperties() {
    return {
      'properties': {
        'alpha-brd': '0.55',
        'alpha-brdf': '1',
        'alpha-brdh': '0.9',
        'bg': 'color_11',
        'bgd': '#FFFFFF',
        'bge': '#FFFFFF',
        'bgh': 'color_11',
        'boxShadowToggleOn-shd': 'false',
        'brd': 'color_15',
        'brdd': '#DBDBDB',
        'brde': '#f60419',
        'brdf': 'color_18',
        'brdh': 'color_15',
        'brw': '1',
        'fnt': 'font_8',
        'rd': '0px',
        'shd': '0px 1px 4px 0px rgba(0,0,0,0.6)',
        'txt': 'color_15',
        'txt2': 'color_14',
        'txtd': '#DBDBDB',
      },
    }
  }
}
