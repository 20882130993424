import _ from 'lodash'
import { IGeneralField } from './general-field'
import { createRecommendedFields } from './recommended-fields'
import { createCustomFields } from './custom-fields'
import { createGeneralFields } from './general-fields'
import { createRegistrationFields } from './registration-form-fields'
import { createAdiFields } from './adi-form-fields'
import { MainEmail } from './custom-fields/definitions/main-email'
import { GeneralItemsList } from './general-fields/definitions/general-items-list'
import { GeneralCustomAmount } from './general-fields/definitions/general-custom-amount'

export const fieldsTypes = fieldsDefinitions => _.map(fieldsDefinitions, field => field.fieldType)

class FieldsStore {
  public allFieldsData: { [key in FieldPreset]?: IGeneralField }
  public recommendedFields: IGeneralField[]
  public customFields: IGeneralField[]
  public generalFields: IGeneralField[]
  public registrationFields: IGeneralField[]
  public adiFields: IGeneralField[]

  constructor() {
    this.recommendedFields = createRecommendedFields()
    this.customFields = createCustomFields()
    this.generalFields = createGeneralFields()
    this.registrationFields = createRegistrationFields()
    this.adiFields = createAdiFields()

    this.allFieldsData = _.reduce(
      [
        ...this.recommendedFields,
        ...this.customFields,
        ...this.generalFields,
        ...this.registrationFields,
        ...this.adiFields,
        new MainEmail(), // contact form field, used in contact form migration
        new GeneralItemsList(), // used as part of payment wizard flow
        new GeneralCustomAmount() // used as part of payment wizard flow
      ],
      (acc, field: IGeneralField) => {
        acc[field.fieldType] = field
        return acc
      },
      {},
    )
  }
}

export const fieldsStore = new FieldsStore()
