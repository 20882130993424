import _ from 'lodash'
import { DynamicContactFormEnhancer, skinWithCenterMessages } from './contact-form-service'
import { ROLE_MESSAGE, FIELDS_ROLES } from '../../../constants/roles'

export const enhanceFormWithDesktopStructure: DynamicContactFormEnhancer = (
  contactForm: DynamicContactForm,
  convertedForm: RawComponentStructure,
  { desktopLayouts: layouts },
) => {
  return {
    ...convertedForm,
    components: convertedForm.components.map((component, index) => {
      const { layout, inputHeight } = getLayout(
        { skin: contactForm.skin, contactFormWidth: contactForm.layout.width },
        component,
        layouts[index],
      )
      return _.includes(FIELDS_ROLES, component.role)
        ? {
            ...component,
            layout,
            props: {
              ...component.props,
              inputHeight,
              textPadding: 5,
              labelMargin: 5,
            },
          }
        : { ...component, layout }
    }),
  }
}

export const getLayout = (
  { skin, contactFormWidth },
  component: RawComponentStructure,
  compLayout,
): { layout: FieldLayout; inputHeight: number | null } => {
  const labelLayout = _.get(compLayout, '[1]')
  const inputLayout = _.get(compLayout, '[0].height') + 1
  const layout = _.omit(
    labelLayout
      ? {
          ...labelLayout,
          y: labelLayout.y + 8,
          height: labelLayout.height + inputLayout,
        }
      : compLayout,
    'fontSize',
  )

  if (component.role === ROLE_MESSAGE && _.includes(skinWithCenterMessages, skin)) {
    return { layout: { ...layout, x: 0, width: contactFormWidth }, inputHeight: null }
  }

  return { layout, inputHeight: Math.max(inputLayout || _.get(compLayout, 'height'), 25) }
}
