import _ from 'lodash'
import { COMPONENT_TYPES } from '@wix/forms-common'
import { innerText } from '../../../utils/utils'
import { ROLE_MESSAGE, ROLE_TITLE } from '../../../constants/roles'
import { FontOption } from '@wix/platform-editor-sdk'
import { skinWithCenterMessages } from './contact-form-service'

type SkinTranslator = {
  defaultValues: ContactFormStyleDefaultValues
  translator: () => {
    [key in COMPONENT_TYPES]?:
      | ContactFormStyleTranslator
      | { [key in string]: ContactFormStyleTranslator }
  }
}

type ContactFormStyleDefaultValues = {
  properties: StyleTranslator
  propertiesSource: StyleTranslator
}
type ContactFormStyleTranslator = StyleTranslator | TextTranslator

type StyleTranslator = {
  [key in string]: string | number | ((contactForm: ContactForm, payload) => string)
}
type TextTranslator = (
  contactForm: ContactForm,
  contactFormStyleDefaultValues: ContactFormStyleDefaultValues,
  text: string,
  fontOptions: FontOption[],
) => string

const baseTextInputTranslator = {
  shd: 'shd',
  'boxShadowToggleOn-shd': 'boxShadowToggleOn-shd',
  rd: 'rd',
  fnt: 'fnt',
  fntlbl: 'fnt',
  bg: 'bg1',
  'alpha-bg': 'alpha-bg1',
  brw: 'brw',
  brwh: 'brw',
  brwf: 'brw',
  brwd: 'brw',
  brwe: 'brwe',
  brd: 'brd',
  'alpha-brd': 'alpha-brd',
  brdh: 'brd',
  'alpha-brdh': 'alpha-brd',
  brdf: 'brd',
  'alpha-brdf': 'alpha-brd',
  brdd: 'brd',
  'alpha-brdd': 'alpha-brd',
  txt: 'txt1',
  brde: 'txtError',
  'alpha-brde': 'alpha-txtError',
  bge: 'bg1',
  'alpha-bge': 'alpha-bg1',
  txt2: 'txt3',
  bgh: 'bg1',
  'alpha-bgh': 'alpha-bg1',
  bgf: 'bg1',
  'alpha-bgf': 'alpha-bg1',
  txtlbl: 'txt3',
  txtlblrq: 'txt3',
}

const baseButtonTranslator = {
  shd: 'shd',
  'boxShadowToggleOn-shd': 'boxShadowToggleOn-shd',
  rd: 'rd',
  bg: 'bg2',
  'alpha-bg': 'alpha-bg2',
  bgh: 'bg2',
  'alpha-bgh': 'alpha-bg2',
  txt: 'txt2',
  txth: 'txt2',
  fnt: 'fnt2',
}

const defaultProperties = {
  bg1: 'color_11',
  bg2: 'color_18',
  brd: 'color_15',
  brw: 0,
  fnt: 'font_7',
  fnt2: 'font_7',
  labelTxt: '#a9a9a9',
  rd: '5px',
  shd: '0 1px 4px rgba(0, 0, 0, 0.6)',
  'boxShadowToggleOn-shd': 'false',
  txt1: 'color_15',
  txt2: 'color_15',
  txt3: 'color_15',
  txtError: '#8b0000',
  txtSuccess: '#bada55',
  'alpha-txtError': 1,
  brwe: 1,
}

const defaultPropertiesSource = {
  bg1: 'theme',
  bg2: 'theme',
  brd: 'theme',
  brw: 'value',
  fnt: 'theme',
  fnt2: 'theme',
  labelTxt: 'value',
  rd: 'value',
  shd: 'value',
  txt1: 'theme',
  txt2: 'theme',
  txt3: 'theme',
  txtError: 'value',
  txtSuccess: 'value',
  'alpha-txtError': 'value',
  brwe: 'value',
  'boxShadowToggleOn-shd': 'value',
}

const defaultSubscribeProperties = {
  bg1: 'color_11',
  bg2: 'color_19',
  bg3: 'color_12',
  bg4: 'color_18',
  brd: 'color_12',
  'alpha-bg1': 1,
  'alpha-bg2': 1,
  'alpha-bg3': 1,
  'alpha-bg4': 1,
  'alpha-brd': 1,
  brw: '1px',
  efnt: 'font_9',
  fnt: 'font_9',
  fnt2: 'font_9',
  labelTxt: 'color_12',
  brwe: 1,
  shd: '0 1px 4px rgba(0, 0, 0, 0.6)',
  tfnt: 'font_6',
  titleColor: 'color_15',
  txt1: 'color_15',
  txt2: 'color_11',
  txt3: 'color_15',
  txtError: '#8b0000',
  txtSuccess: '#bada55',
  'boxShadowToggleOn-shd': 'false',
}

const defaultSubscribePropertiesSource = {
  bg1: 'theme',
  bg2: 'theme',
  bg3: 'theme',
  bg4: 'theme',
  brd: 'theme',
  'alpha-bg1': 'value',
  'alpha-bg2': 'value',
  'alpha-bg3': 'value',
  'alpha-bg4': 'value',
  'alpha-brd': 'value',
  brw: 'value',
  efnt: 'theme',
  fnt: 'theme',
  fnt2: 'theme',
  labelTxt: 'theme',
  brwe: 'value',
  shd: 'value',
  tfnt: 'value',
  titleColor: 'value',
  txt1: 'value',
  txt2: 'value',
  txt3: 'value',
  txtError: 'value',
  txtSuccess: 'value',
  'boxShadowToggleOn-shd': 'value',
}

const defaultSubscribeInputTranslator = {
  fnt: 'fnt',
  fntlbl: 'fnt',
  brw: 'brw',
  brwh: 'brw',
  brwf: 'brw',
  brwd: 'brw',
  brwe: 'brwe',
  brd: 'brd',
  'alpha-brd': 'alpha-brd',
  brdh: 'brd',
  'alpha-brdh': 'alpha-brd',
  brdf: 'brd',
  'alpha-brdf': 'alpha-brd',
  txt: 'txt1',
  txtlbl: 'labelTxt',
  txtlblrq: 'labelTxt',
  txt2: 'labelTxt',
  brde: 'txtError',
  bge: 'bg1',
  'alpha-bge': 'alpha-bg1',
  bgh: 'bg1',
  'alpha-bgh': 'alpha-bg1',
  bgf: 'bg1',
  'alpha-bgf': 'alpha-bg1',
  bg: 'bg1',
  'alpha-bg': 'alpha-bg1',
}

const defaultSubscribeButtonTranslator = {
  bg: 'bg2',
  'alpha-bg': 'alpha-bg2',
  bgh: 'bg4',
  'alpha-bgh': 'alpha-bg4',
  txt: 'txt2',
  txth: 'txt3',
  fnt: 'fnt2',
}

const defaultSubscribeFormContainerTranslator = {
  bg: 'bg3',
  'alpha-bg': 'alpha-bg3',
  shd: 'shd',
  'boxShadowToggleOn-shd': 'boxShadowToggleOn-shd',
}

const getDefaultSubscribeTextTranslator = ({
  messageFntKey,
  messageColorKey,
  titleFntKey,
  titleColorKey,
}) => ({
  [ROLE_MESSAGE]: (
    contactForm: ContactForm,
    contactFormStyleDefaultValues: ContactFormStyleDefaultValues,
    text: string,
    fontOptions,
  ) =>
    translateComplexText(
      contactForm,
      contactFormStyleDefaultValues,
      text,
      fontOptions,
      {
        fntKey: messageFntKey,
        colorKey: messageColorKey,
      },
      true,
    ),
  [ROLE_TITLE]: (
    contactForm: ContactForm,
    contactFormStyleDefaultValues: ContactFormStyleDefaultValues,
    text: string,
    fontOptions,
  ) =>
    translateComplexText(
      contactForm,
      contactFormStyleDefaultValues,
      text,
      fontOptions,
      {
        fntKey: titleFntKey,
        colorKey: titleColorKey,
      },
      false,
    ),
})

export const skinToStyleMapping: { [key in string]: SkinTranslator } = {
  'contactform.FullWidthButtonSkin': {
    defaultValues: {
      properties: {
        ...defaultProperties,
        brd: 'color_18',
        brw: 1,
      },
      propertiesSource: defaultPropertiesSource,
    },
    translator: () => {
      const textInputTranslator = _.omit(baseTextInputTranslator, ['rd'])
      return {
        [COMPONENT_TYPES.TEXT_INPUT]: textInputTranslator,
        [COMPONENT_TYPES.TEXT_AREA]: textInputTranslator,
        [COMPONENT_TYPES.SITE_BUTTON]: _.omit(baseButtonTranslator, [
          'boxShadowToggleOn-shd',
          'shd',
          'rd',
        ]),
        [COMPONENT_TYPES.TEXT]: translateMessage,
      }
    },
  },
  'wysiwyg.viewer.skins.contactform.BasicContactFormSkin': {
    defaultValues: {
      properties: defaultProperties,
      propertiesSource: defaultPropertiesSource,
    },
    translator: () => {
      const textInputTranslator = {
        ...baseTextInputTranslator,
        txt2: 'labelTxt',
        txtlbl: 'labelTxt',
        txtlblrq: 'labelTxt',
      }
      return {
        [COMPONENT_TYPES.TEXT_INPUT]: textInputTranslator,
        [COMPONENT_TYPES.TEXT_AREA]: textInputTranslator,
        [COMPONENT_TYPES.SITE_BUTTON]: baseButtonTranslator,
        [COMPONENT_TYPES.TEXT]: translateMessage,
      }
    },
  },
  'wysiwyg.viewer.skins.contactform.DefaultContactForm': {
    defaultValues: {
      properties: defaultProperties,
      propertiesSource: defaultPropertiesSource,
    },
    translator: () => {
      const textInputTranslator = {
        ...baseTextInputTranslator,
        txt2: 'labelTxt',
        txtlbl: 'labelTxt',
        txtlblrq: 'labelTxt',
      }
      return {
        [COMPONENT_TYPES.TEXT_INPUT]: textInputTranslator,
        [COMPONENT_TYPES.TEXT_AREA]: textInputTranslator,
        [COMPONENT_TYPES.SITE_BUTTON]: baseButtonTranslator,
        [COMPONENT_TYPES.TEXT]: translateMessage,
      }
    },
  },
  'contactform.LineOnlySkin': {
    defaultValues: {
      properties: {
        brd: 'color_15',
        brd2: 'color_18',
        fnt: 'font_7',
        fnt2: 'font_7',
        txt1: 'color_15',
        txt3: 'color_15',
        txt4: 'color_15',
        txtError: '#FF0000',
        txtSuccess: '#bada55',
        brw: '0px 0px 1px 0px',
        bg1: '#FFFFFF',
        'alpha-bg1': 0,
      },
      propertiesSource: {
        brd: 'theme',
        brd2: 'theme',
        fnt: 'theme',
        fnt2: 'theme',
        txt1: 'theme',
        txt3: 'theme',
        txt4: 'theme',
        txtError: 'value',
        txtSuccess: 'value',
        brw: 'value',
        bg1: 'value',
        'alpha-bg1': 'value',
      },
    },
    translator: () => {
      const textInputTranslator = {
        fnt: 'fnt',
        fntlbl: 'fnt',
        brw: 'brw',
        brwh: 'brw',
        brwf: 'brw',
        brwd: 'brw',
        brwe: 'brw',
        brd: 'brd',
        'alpha-brd': 'alpha-brd',
        brdh: 'brd',
        'alpha-brdh': 'alpha-brd',
        brdf: 'brd2',
        'alpha-brdf': 'alpha-brd2',
        txt: 'txt1',
        txtlbl: 'txt3',
        txtlblrq: 'txt3',
        txt2: 'txt3',
        brde: 'txtError',
        bge: 'bg1',
        'alpha-bge': 'alpha-bg1',
        bgh: 'bg1',
        'alpha-bgh': 'alpha-bg1',
        bgf: 'bg1',
        'alpha-bgf': 'alpha-bg1',
        bg: 'bg1',
        'alpha-bg': 'alpha-bg1',
      }
      return {
        [COMPONENT_TYPES.TEXT_INPUT]: textInputTranslator,
        [COMPONENT_TYPES.TEXT_AREA]: textInputTranslator,
        [COMPONENT_TYPES.SITE_BUTTON]: {
          bg: 'bg1',
          'alpha-bg': 'alpha-bg1',
          bgh: 'bg1',
          'alpha-bgh': 'alpha-bg1',
          txt: 'txt4',
          txth: 'txt4',
          fnt: 'fnt2',
        },
        [COMPONENT_TYPES.TEXT]: translateMessage,
      }
    },
  },
  'wysiwyg.viewer.skins.contactform.VerticalFormLabelsLeft': {
    defaultValues: {
      properties: defaultProperties,
      propertiesSource: defaultPropertiesSource,
    },
    translator: () => ({
      [COMPONENT_TYPES.TEXT_INPUT]: baseTextInputTranslator,
      [COMPONENT_TYPES.TEXT_AREA]: baseTextInputTranslator,
      [COMPONENT_TYPES.SITE_BUTTON]: baseButtonTranslator,
      [COMPONENT_TYPES.TEXT]: {
        [ROLE_MESSAGE]: translateMessage,
        input_label_left: (
          contactForm: ContactForm,
          contactFormStyleDefaultValues: ContactFormStyleDefaultValues,
          text: string,
          fontOptions,
        ) =>
          translateComplexText(
            contactForm,
            contactFormStyleDefaultValues,
            text,
            fontOptions,
            {
              fntKey: 'fnt',
              colorKey: 'txt3',
            },
            false,
          ),
      },
    }),
  },
  'wysiwyg.viewer.skins.contactform.VerticalForm': {
    defaultValues: {
      properties: defaultProperties,
      propertiesSource: defaultPropertiesSource,
    },
    translator: () => ({
      [COMPONENT_TYPES.TEXT_INPUT]: baseTextInputTranslator,
      [COMPONENT_TYPES.TEXT_AREA]: baseTextInputTranslator,
      [COMPONENT_TYPES.SITE_BUTTON]: baseButtonTranslator,
      [COMPONENT_TYPES.TEXT]: translateMessage,
    }),
  },
  'contactform.FieldAnimationSkin': {
    defaultValues: {
      properties: {
        bg1: 'color_11',
        bg2: 'color_18',
        bg3: 'color_11',
        fnt: 'font_7',
        fnt2: 'font_7',
        txt1: 'color_15',
        txt2: 'color_15',
        txt4: 'color_15',
        txtError: '#8b0000',
        txtSuccess: '#bada55',
        brw: 0,
      },
      propertiesSource: {
        bg1: 'theme',
        bg2: 'theme',
        bg3: 'theme',
        fnt: 'theme',
        fnt2: 'theme',
        txt1: 'theme',
        txt2: 'theme',
        txt4: 'theme',
        txtError: 'value',
        txtSuccess: 'value',
        brw: 'value',
      },
    },
    translator: () => {
      const textInputTranslator = {
        fnt: 'fnt',
        fntlbl: 'fnt',
        brw: 'brw',
        brwh: 'brw',
        brwf: 'brw',
        brwd: 'brw',
        brwe: 'brw',
        txt: 'txt1',
        txtlbl: 'txt4',
        txtlblrq: 'txt4',
        txt2: 'txt4',
        bge: 'bg3',
        'alpha-bge': 'alpha-bg3',
        bgh: 'bg3',
        'alpha-bgh': 'alpha-bg3',
        bgf: 'bg1',
        'alpha-bgf': 'alpha-bg1',
        bg: 'bg3',
        'alpha-bg': 'alpha-bg3',
      }
      return {
        [COMPONENT_TYPES.TEXT_INPUT]: textInputTranslator,
        [COMPONENT_TYPES.TEXT_AREA]: textInputTranslator,
        [COMPONENT_TYPES.SITE_BUTTON]: {
          bg: 'bg2',
          'alpha-bg': 'alpha-bg2',
          bgh: 'bg2',
          'alpha-bgh': 'alpha-bg2',
          txt: 'txt2',
          txth: 'txt2',
          fnt: 'fnt2',
        },
        [COMPONENT_TYPES.TEXT]: translateMessage,
      }
    },
  },
  'contactform.OverlappingButtonSkin': {
    defaultValues: {
      properties: {
        bg1: 'color_11',
        bg2: 'color_18',
        brd: 'color_15',
        brw: 1,
        brw2: 1,
        fnt: 'font_7',
        fnt2: 'font_7',
        shd: '0 0px 0px rgba(0, 0, 0, 0)',
        txt1: 'color_15',
        txt2: 'color_15',
        labelTxt: 'color_15',
        txtSuccess: '#bada55',
      },
      propertiesSource: {
        bg1: 'theme',
        bg2: 'theme',
        brd: 'theme',
        brw: 'value',
        brw2: 'value',
        fnt: 'theme',
        fnt2: 'theme',
        shd: 'value',
        txt1: 'theme',
        txt2: 'theme',
        txt3: 'theme',
        txtError: 'value',
        txtSuccess: 'value',
      },
    },
    translator: () => {
      const textInputTranslator = _.omit(
        {
          ...baseTextInputTranslator,
          txt2: 'labelTxt',
          txtlbl: 'labelTxt',
          txtlblrq: 'labelTxt',
          brde: 'brd',
          brwe: 'brw',
        },
        ['rd'],
      )
      return {
        [COMPONENT_TYPES.TEXT_INPUT]: textInputTranslator,
        [COMPONENT_TYPES.TEXT_AREA]: textInputTranslator,
        [COMPONENT_TYPES.SITE_BUTTON]: {
          bg: 'bg2',
          'alpha-bg': 'alpha-bg2',
          bgh: 'bg2',
          'alpha-bgh': 'alpha-bg2',
          txt: 'txt2',
          txth: 'txt2',
          fnt: 'fnt2',
          brw: 'brw2',
          brd: 'brd',
          'alpha-brd': 'alpha-brd',
          brdh: 'brd',
          'alpha-brdh': 'alpha-brd',
          brdd: 'brd',
          'alpha-brdd': 'alpha-brd',
        },
        [COMPONENT_TYPES.TEXT]: translateMessage,
      }
    },
  },
  'wysiwyg.viewer.skins.contactform.HorizontalContactFormSkin': {
    defaultValues: {
      properties: defaultProperties,
      propertiesSource: defaultPropertiesSource,
    },
    translator: () => {
      const textInputTranslator = {
        ...baseTextInputTranslator,
        txt2: 'labelTxt',
        txtlbl: 'labelTxt',
        txtlblrq: 'labelTxt',
      }
      return {
        [COMPONENT_TYPES.TEXT_INPUT]: textInputTranslator,
        [COMPONENT_TYPES.TEXT_AREA]: textInputTranslator,
        [COMPONENT_TYPES.SITE_BUTTON]: baseButtonTranslator,
        [COMPONENT_TYPES.TEXT]: translateMessage,
      }
    },
  },
  //7
  'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormBoxLayoutFlat': {
    defaultValues: {
      properties: defaultSubscribeProperties,
      propertiesSource: defaultSubscribePropertiesSource,
    },
    translator: () => {
      return {
        [COMPONENT_TYPES.TEXT_INPUT]: defaultSubscribeInputTranslator,
        [COMPONENT_TYPES.SITE_BUTTON]: defaultSubscribeButtonTranslator,
        [COMPONENT_TYPES.FORM_CONTAINER]: defaultSubscribeFormContainerTranslator,
        [COMPONENT_TYPES.TEXT]: getDefaultSubscribeTextTranslator({
          messageFntKey: 'efnt',
          messageColorKey: 'txtSuccess',
          titleFntKey: 'tfnt',
          titleColorKey: 'titleColor',
        }),
      }
    },
  },
  //5
  'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormPlaceholderSkin': {
    defaultValues: {
      properties: {
        ...defaultSubscribeProperties,
        phs_bg4: 'color_12',
        'alpha-phs_bg4': 1,
        phs_txt3: 'color_15',
        rd: '0',
      },
      propertiesSource: {
        ...defaultSubscribePropertiesSource,
        ph_bg4: 'theme',
        'alpha-ph_bg4': 'value',
        phs_txt3: 'theme',
        rd: 'value',
      },
    },
    translator: () => {
      return {
        [COMPONENT_TYPES.TEXT_INPUT]: {
          ...defaultSubscribeInputTranslator,
          shd: 'shd',
          'boxShadowToggleOn-shd': 'boxShadowToggleOn-shd',
          rd: 'rd',
        },
        [COMPONENT_TYPES.SITE_BUTTON]: {
          ...defaultSubscribeButtonTranslator,
          bgh: 'phs_bg4',
          'alpha-bgh': 'alpha-phs_bg4',
          txth: 'phs_txt3',
          rd: 'rd',
          shd: 'shd',
          'boxShadowToggleOn-shd': 'boxShadowToggleOn-shd',
        },
        [COMPONENT_TYPES.TEXT]: getDefaultSubscribeTextTranslator({
          messageFntKey: 'efnt',
          messageColorKey: 'txtSuccess',
          titleFntKey: 'tfnt',
          titleColorKey: 'titleColor',
        }),
      }
    },
  },
  //7
  'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormBoxLayoutEnvelope': {
    defaultValues: {
      properties: {
        ...defaultSubscribeProperties,
        'form-brd': '#FFFFFF',
        'form-brw': 15,
        rd: '5px',
        'form-shd': '0 1px 4px rgba(0, 0, 0, 0.6)',
        'form-rd': '5px',
        'form-boxShadowToggleOn-shd': 'true',
      },
      propertiesSource: {
        ...defaultSubscribePropertiesSource,
        'form-brd': 'value',
        'form-brw': 'value',
        rd: 'value',
        'form-shd': 'value',
        'form-rd': 'value',
        'form-boxShadowToggleOn-shd': 'value',
      },
    },
    translator: () => {
      return {
        [COMPONENT_TYPES.TEXT_INPUT]: defaultSubscribeInputTranslator,
        [COMPONENT_TYPES.SITE_BUTTON]: defaultSubscribeButtonTranslator,
        [COMPONENT_TYPES.FORM_CONTAINER]: {
          bg: (_contactForm: ContactForm, payload) => payload.backgroundColor.color,
          'alpha-bg': 'alpha-bg3',
          brd: 'form-brd',
          brw: 'form-brw',
          rd: 'form-rd',
          shd: 'form-shd',
          'boxShadowToggleOn-shd': 'form-boxShadowToggleOn-shd',
        },
        [COMPONENT_TYPES.TEXT]: getDefaultSubscribeTextTranslator({
          messageFntKey: 'efnt',
          messageColorKey: 'txtSuccess',
          titleFntKey: 'tfnt',
          titleColorKey: 'titleColor',
        }),
      }
    },
  },
  //7
  'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormLineLayoutFlat': {
    defaultValues: {
      properties: {
        'alpha-llf_bg1': 1,
        'alpha-llf_bg2': 1,
        'alpha-llf_bg3': 1,
        'alpha-llf_bg4': 1,
        'alpha-llf_brd': 1,
        'boxShadowToggleOn-llf_shd': 'false',
        llf_bg1: 'color_11',
        llf_bg2: 'color_19',
        llf_bg3: 'color_12',
        llf_bg4: 'color_18',
        llf_brd: 'color_12',
        llf_brw: '1px',
        llf_brwe: 1,
        llf_efnt: 'font_9',
        llf_fnt: 'font_9',
        llf_fnt2: 'font_9',
        llf_labelTxt: 'color_12',
        llf_shd: '0 1px 4px rgba(0, 0, 0, 0.6)',
        llf_tfnt: 'font_6',
        llf_titleColor: 'color_15',
        llf_txt1: 'color_15',
        llf_txt2: 'color_11',
        llf_txt3: 'color_15',
        llf_txtError: '#8b0000',
        llf_txtSuccess: '#bada55',
      },
      propertiesSource: {
        'alpha-llf_bg1': 'value',
        'alpha-llf_bg2': 'value',
        'alpha-llf_bg3': 'value',
        'alpha-llf_bg4': 'value',
        'alpha-llf_brd': 'value',
        'boxShadowToggleOn-llf_shd': 'value',
        llf_bg1: 'theme',
        llf_bg2: 'theme',
        llf_bg3: 'theme',
        llf_bg4: 'theme',
        llf_brd: 'theme',
        llf_brw: 'value',
        llf_brwe: 'value',
        llf_efnt: 'theme',
        llf_fnt: 'theme',
        llf_fnt2: 'theme',
        llf_labelTxt: 'theme',
        llf_shd: 'value',
        llf_tfnt: 'value',
        llf_titleColor: 'value',
        llf_txt1: 'value',
        llf_txt2: 'value',
        llf_txt3: 'value',
        llf_txtError: 'value',
        llf_txtSuccess: 'value',
      },
    },
    translator: () => {
      return {
        [COMPONENT_TYPES.TEXT_INPUT]: {
          'alpha-bg': 'alpha-llf_bg1',
          'alpha-bge': 'alpha-llf_bg1',
          'alpha-bgf': 'alpha-llf_bg1',
          'alpha-bgh': 'alpha-llf_bg1',
          'alpha-brd': 'alpha-llf_brd',
          'alpha-brdf': 'alpha-llf_brd',
          'alpha-brdh': 'alpha-llf_brd',
          bg: 'llf_bg1',
          bge: 'llf_bg1',
          bgf: 'llf_bg1',
          bgh: 'llf_bg1',
          brd: 'llf_brd',
          brde: 'llf_txtError',
          brdf: 'llf_brd',
          brdh: 'llf_brd',
          brw: 'llf_brw',
          brwd: 'llf_brw',
          brwe: 'llf_brwe',
          brwf: 'llf_brw',
          brwh: 'llf_brw',
          fnt: 'llf_fnt',
          fntlbl: 'llf_fnt',
          txt: 'llf_txt1',
          txt2: 'llf_labelTxt',
          txtlbl: 'llf_labelTxt',
          txtlblrq: 'llf_labelTxt',
        },
        [COMPONENT_TYPES.SITE_BUTTON]: {
          'alpha-bg': 'alpha-llf_bg2',
          'alpha-bgh': 'alpha-llf_bg4',
          bg: 'llf_bg2',
          bgh: 'llf_bg4',
          fnt: 'llf_fnt2',
          txt: 'llf_txt2',
          txth: 'llf_txt3',
        },
        [COMPONENT_TYPES.FORM_CONTAINER]: {
          'alpha-bg': 'alpha-llf_bg3',
          bg: 'llf_bg3',
          'boxShadowToggleOn-shd': 'boxShadowToggleOn-llf_shd',
          shd: 'llf_shd',
        },
        [COMPONENT_TYPES.TEXT]: getDefaultSubscribeTextTranslator({
          messageFntKey: 'llf_efnt',
          messageColorKey: 'llf_txtSuccess',
          titleFntKey: 'llf_tfnt',
          titleColorKey: 'llf_titleColor',
        }),
      }
    },
  },
  //7
  'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormLineLayoutTransparentWithIcon': {
    defaultValues: {
      properties: {
        'alpha-llf_bg1': 1,
        'alpha-llf_bg2': 1,
        'alpha-llf_bg3': 1,
        'alpha-llf_bg4': 1,
        'alpha-llf_brd': 1,
        'boxShadowToggleOn-llf_shd': 'false',
        llf_bg1: 'color_11',
        llf_bg2: 'color_19',
        llf_bg4: 'color_18',
        llf_brd: 'color_12',
        llf_brw: '1px',
        llf_brwe: 1,
        llf_efnt: 'font_9',
        llf_fnt: 'font_9',
        llf_fnt2: 'font_9',
        llf_labelTxt: 'color_12',
        llf_shd: '0 1px 4px rgba(0, 0, 0, 0.6)',
        llf_tfnt: 'font_6',
        llf_titleColor: 'color_15',
        llf_txt1: 'color_15',
        llf_txt2: 'color_11',
        llf_txt3: 'color_15',
        llf_txtError: '#8b0000',
        llf_txtSuccess: '#bada55',
        llf_icon_color: 'color_12',
        'alpha-llf_icon_color': 1,
        llf_bg3: 'color_11',
      },
      propertiesSource: {
        'alpha-llf_bg1': 'value',
        'alpha-llf_bg2': 'value',
        'alpha-llf_bg3': 'value',
        'alpha-llf_bg4': 'value',
        'alpha-llf_brd': 'value',
        'boxShadowToggleOn-llf_shd': 'value',
        llf_bg1: 'theme',
        llf_bg2: 'theme',
        llf_bg3: 'theme',
        llf_bg4: 'theme',
        llf_brd: 'theme',
        llf_brw: 'value',
        llf_brwe: 'value',
        llf_efnt: 'theme',
        llf_fnt: 'theme',
        llf_fnt2: 'theme',
        llf_labelTxt: 'theme',
        llf_shd: 'value',
        llf_tfnt: 'value',
        llf_titleColor: 'value',
        llf_txt1: 'value',
        llf_txt2: 'value',
        llf_txt3: 'value',
        llf_txtError: 'value',
        llf_txtSuccess: 'value',

        llf_icon_color: 'theme',
        'alpha-llf_icon_color': 'value',
      },
    },
    translator: () => {
      return {
        [COMPONENT_TYPES.TEXT_INPUT]: {
          'alpha-bg': 'alpha-llf_bg1',
          'alpha-bge': 'alpha-llf_bg1',
          'alpha-bgf': 'alpha-llf_bg1',
          'alpha-bgh': 'alpha-llf_bg1',
          'alpha-brd': 'alpha-llf_brd',
          'alpha-brdf': 'alpha-llf_brd',
          'alpha-brdh': 'alpha-llf_brd',
          bg: 'llf_bg1',
          bge: 'llf_bg1',
          bgf: 'llf_bg1',
          bgh: 'llf_bg1',
          brd: 'llf_brd',
          brde: 'llf_txtError',
          brdf: 'llf_brd',
          brdh: 'llf_brd',
          brw: 'llf_brw',
          brwd: 'llf_brw',
          brwe: 'llf_brwe',
          brwf: 'llf_brw',
          brwh: 'llf_brw',
          fnt: 'llf_fnt',
          fntlbl: 'llf_fnt',
          txt: 'llf_txt1',
          txt2: 'llf_labelTxt',
          txtlbl: 'llf_labelTxt',
          txtlblrq: 'llf_labelTxt',
        },
        [COMPONENT_TYPES.SITE_BUTTON]: {
          'alpha-bg': 'alpha-llf_bg2',
          'alpha-bgh': 'alpha-llf_bg4',
          bg: 'llf_bg2',
          bgh: 'llf_bg4',
          fnt: 'llf_fnt2',
          txt: 'llf_txt2',
          txth: 'llf_txt3',
        },
        [COMPONENT_TYPES.FORM_CONTAINER]: {
          'alpha-bg': 'alpha-llf_bg3',
          bg: 'llf_bg3',
          'boxShadowToggleOn-shd': 'boxShadowToggleOn-llf_shd',
          shd: 'llf_shd',
        },
        [COMPONENT_TYPES.TEXT]: getDefaultSubscribeTextTranslator({
          messageFntKey: 'llf_efnt',
          messageColorKey: 'llf_txtSuccess',
          titleFntKey: 'llf_tfnt',
          titleColorKey: 'llf_titleColor',
        }),
        'mobile.core.components.Container': {
          bg: 'llf_icon_color',
          'alpha-bg': 'alpha-llf_icon_color:',
        },
      }
    },
  },
  //7
  'wysiwyg.common.components.subscribeform.viewer.skins.SubscribeFormBoxLayoutShiny': {
    defaultValues: {
      properties: {
        'alpha-bls_bg1': 1,
        'alpha-bls_bg2': 1,
        'alpha-bls_bg3': 1,
        'alpha-bls_bg4': 1,
        'alpha-bls_brd': 1,
        bls_bg1: 'color_11',
        bls_bg2: 'color_19',
        bls_bg3: 'color_12',
        bls_bg4: 'color_18',
        bls_brd: 'color_12',
        bls_brw: '1px',
        bls_brwe: 1,
        bls_efnt: 'font_9',
        bls_fnt: 'font_9',
        bls_fnt2: 'font_9',
        bls_labelTxt: 'color_12',
        bls_tfnt: 'font_6',
        bls_titleColor: 'color_15',
        bls_txt1: 'color_15',
        bls_txt2: 'color_11',
        bls_txt3: 'color_15',
        bls_txtError: '#8b0000',
        bls_txtSuccess: '#bada55',
        'boxShadowToggleOn-bls_shd': 'false',
        bls_shd_input: 'inset 1px 1px 0 1px rgba(50, 50, 50, 0.2)',
        bls_shd_subscribe_button: 'inset 0 -15px 5px 0 rgba(50, 50, 50, 0.4)',
        bls_shd_input_rtl: 'inset -1px 1px 1px 0 rgba(50, 50, 50, 0.75)',
        bls_shd: '0 2px 1px 0 rgba(50, 50, 50, 0.2)',
        'boxShadowToggleOn-bls_shd_input': 'true',
        'boxShadowToggleOn-bls_shd_subscribe_button': 'true',
        'boxShadowToggleOn-bls_shd_input_rtl': 'true',
      },
      propertiesSource: {
        'alpha-bls_bg1': 'value',
        'alpha-bls_bg2': 'value',
        'alpha-bls_bg3': 'value',
        'alpha-bls_bg4': 'value',
        'alpha-bls_brd': 'value',
        bls_bg1: 'theme',
        bls_bg2: 'theme',
        bls_bg3: 'theme',
        bls_bg4: 'theme',
        bls_brd: 'theme',
        bls_brw: 'value',
        bls_brwe: 'value',
        bls_efnt: 'theme',
        bls_fnt: 'theme',
        bls_fnt2: 'theme',
        bls_labelTxt: 'theme',
        bls_shd: 'value',
        bls_tfnt: 'value',
        bls_titleColor: 'value',
        bls_txt1: 'value',
        bls_txt2: 'value',
        bls_txt3: 'value',
        bls_txtError: 'value',
        bls_txtSuccess: 'value',
        'boxShadowToggleOn-bls_shd': 'value',
        bls_shd_input: 'value',
        bls_shd_subscribe_button: 'value',
        bls_shd_input_rtl: 'value',
        'boxShadowToggleOn-bls_shd_input': 'value',
        'boxShadowToggleOn-bls_shd_subscribe_button': 'value',
        'boxShadowToggleOn-bls_shd_input_rtl': 'value',
      },
    },
    translator: () => {
      return {
        [COMPONENT_TYPES.TEXT_INPUT]: {
          'alpha-bg': 'alpha-bls_bg1',
          'alpha-bge': 'alpha-bls_bg1',
          'alpha-bgf': 'alpha-bls_bg1',
          'alpha-bgh': 'alpha-bls_bg1',
          'alpha-brd': 'alpha-bls_brd',
          'alpha-brdf': 'alpha-bls_brd',
          'alpha-brdh': 'alpha-bls_brd',
          bg: 'bls_bg1',
          bge: 'bls_bg1',
          bgf: 'bls_bg1',
          bgh: 'bls_bg1',
          brd: 'bls_brd',
          brde: 'bls_txtError',
          brdf: 'bls_brd',
          brdh: 'bls_brd',
          brw: 'bls_brw',
          brwd: 'bls_brw',
          brwe: 'bls_brwe',
          brwf: 'bls_brw',
          brwh: 'bls_brw',
          fnt: 'bls_fnt',
          fntlbl: 'bls_fnt',
          txt: 'bls_txt1',
          txt2: 'bls_labelTxt',
          txtlbl: 'bls_labelTxt',
          txtlblrq: 'bls_labelTxt',
          shd: (contactForm: ContactForm) =>
            contactForm.data.textDirection === 'right' ? 'bls_shd_input_rtl' : 'bls_shd_input',
          'boxShadowToggleOn-shd': (contactForm: ContactForm) =>
            contactForm.data.textDirection === 'right'
              ? 'boxShadowToggleOn-bls_shd_input_rtl'
              : 'boxShadowToggleOn-bls_shd_input',
        },
        [COMPONENT_TYPES.SITE_BUTTON]: {
          'alpha-bg': 'alpha-bls_bg2',
          'alpha-bgh': 'alpha-bls_bg4',
          bg: 'bls_bg2',
          bgh: 'bls_bg4',
          fnt: 'bls_fnt2',
          txt: 'bls_txt2',
          txth: 'bls_txt3',
          shd: 'bls_shd_subscribe_button',
          'boxShadowToggleOn-shd': 'boxShadowToggleOn-bls_shd_subscribe_button',
        },
        [COMPONENT_TYPES.FORM_CONTAINER]: {
          'alpha-bg': 'alpha-bls_bg3',
          bg: 'bls_bg3',
          'boxShadowToggleOn-shd': 'boxShadowToggleOn-bls_shd',
          shd: 'bls_shd',
        },
        [COMPONENT_TYPES.TEXT]: getDefaultSubscribeTextTranslator({
          messageFntKey: 'bls_efnt',
          messageColorKey: 'bls_txtSuccess',
          titleFntKey: 'bls_tfnt',
          titleColorKey: 'bls_titleColor',
        }),
      }
    },
  },
}

const translateComplexText = (
  contactForm: ContactForm,
  contactFormStyleDefaultValues: ContactFormStyleDefaultValues,
  text: string,
  fontOptions: FontOption[],
  { fntKey, colorKey },
  isMessage: boolean,
) => {
  const { properties: font, propertiesSource: fontSource } = getKeyValue(
    contactForm,
    contactFormStyleDefaultValues,
    fntKey,
    {},
  )
  const { properties: color, propertiesSource: colorSource } = getKeyValue(
    contactForm,
    contactFormStyleDefaultValues,
    colorKey,
    {},
  )
  const colorSpan =
    colorSource === 'theme' ? `<span class="${color}">` : `<span style="color:${color};">`
  const textAlignment = getTextAlignment(contactForm, isMessage)
  const dir = getDir(contactForm)
  if (fontSource === 'theme' || _.startsWith(font, 'font_')) {
    return `<p class="${font}" style="text-align:${textAlignment}" dir="${dir}">${colorSpan}${text}</span></p>`
  } else {
    const parsedFont = parseFontStr(font, fontOptions)
    return `<p style="line-height:${parsedFont.lineHeight};text-align:${textAlignment}" dir="${dir}">${colorSpan}<span style="font-variant:${parsedFont.variant}"><span style="font-style:${parsedFont.style}"><span style="font-weight:${parsedFont.weight}"><span style="font-size:${parsedFont.size}"><span style="font-family:${parsedFont.family}">${text}</span></span></span></span></span></span></p>`
  }
}

const translateMessage = (
  contactForm: ContactForm,
  contactFormStyleDefaultValues: ContactFormStyleDefaultValues,
  text: string,
) => {
  const fontSize = '14px'
  const fontFamily = 'helvetica-w01-roman,helvetica-w02-roman,helvetica-lt-w10-roman,sans-serif'
  const { properties, propertiesSource } = getKeyValue(
    contactForm,
    contactFormStyleDefaultValues,
    'txtSuccess',
    {},
  )
  const colorSpan =
    propertiesSource === 'theme'
      ? `<span class="${properties}">`
      : `<span style="color:${properties};">`
  return `<p dir="${getDir(
    contactForm,
  )}" style="font-size: ${fontSize};text-align:${getTextAlignment(
    contactForm,
    true,
  )}">${colorSpan}<span style="font-family:${fontFamily};"><span style="font-size:${fontSize};">${text}</span></span></span></p>`
}

const getTextAlignment = (contactForm: ContactForm, isMessage: boolean) => {
  if (_.includes(skinWithCenterMessages, contactForm.skin) && isMessage) {
    return 'center'
  }
  if (contactForm.skin === 'wysiwyg.viewer.skins.contactform.VerticalFormLabelsLeft') {
    if (isMessage) {
      return 'left'
    } else {
      return contactForm.data.textDirection === 'left' ? 'right' : 'left'
    }
  }
  return contactForm.data.textDirection
}

const getDir = (contactForm: ContactForm) =>
  contactForm.data.textDirection === 'left' ? 'ltr' : 'rtl'

const getKeyValue = (
  contactForm: ContactForm,
  defaultValues: ContactFormStyleDefaultValues,
  key: string | ((contactForm: ContactForm, payload?) => string),
  payload,
) => {
  if (_.isFunction(key) && payload) {
    return {
      properties: key(contactForm, payload),
      propertiesSource: 'value',
    }
  }
  const actualKey = _.isFunction(key) ? key(contactForm) : key
  const { style } = contactForm

  return {
    properties: _.get(
      contactForm.style,
      ['style', 'properties', actualKey],
      defaultValues.properties[actualKey],
    ),
    propertiesSource: _.get(
      style,
      ['style', 'propertiesSource', actualKey],
      defaultValues.propertiesSource[actualKey],
    ),
  }
}

export const translateContactFormStyle = (
  contactFormStyleTranslator: ContactFormStyleTranslator,
  contactFormStyleDefaultValues: ContactFormStyleDefaultValues,
  contactForm: ContactForm,
  componentStructure: RawComponentStructure,
  fontOptions: FontOption[],
  payload?,
): RawComponentStructure => {
  if (componentStructure.componentType === COMPONENT_TYPES.TEXT) {
    return {
      ...componentStructure,
      data: {
        ...componentStructure.data,
        text: (contactFormStyleTranslator as TextTranslator)(
          contactForm,
          contactFormStyleDefaultValues,
          innerText(componentStructure.data.text),
          fontOptions,
        ),
      },
    }
  } else {
    const updatedStyle: ComponentStyle = _.reduce(
      contactFormStyleTranslator,
      (prevStyle, contactFormStyleKey, wixFormsStyleKey) => {
        const { properties, propertiesSource } = getKeyValue(
          contactForm,
          contactFormStyleDefaultValues,
          contactFormStyleKey,
          payload,
        )
        prevStyle.style.properties[wixFormsStyleKey] = properties
        prevStyle.style.propertiesSource[wixFormsStyleKey] = propertiesSource
        return prevStyle
      },
      _.merge(
        {},
        {
          style: {
            propertiesSource: {},
          },
        },
        <ComponentStyle>componentStructure.style,
      ),
    )
    return { ...componentStructure, style: updatedStyle }
  }
}

const parseFontStr = (fontStr: string, fontOptions: FontOption[]) => {
  const split = fontStr.split(' ')
  const sizeSplit = split[3] ? split[3].split('/') : []
  return {
    style: split[0],
    variant: split[1],
    weight: split[2],
    size: sizeSplit[0],
    lineHeight: sizeSplit[1],
    family: getFontFamily(fontStr, fontOptions),
    color: split[5],
    bold: split[2] === 'bold' || parseInt(split[2], 10) >= 700,
    italic: split[0] === 'italic',
  }
}

const getFontFamily = (fontStr: string, fontOptions: FontOption[]): string => {
  const fontFamily = fontStr.split(' ')[4]
  const cleanFontName = fontFamily.replace(/\+/g, ' ').toLowerCase()
  const fonts = _.flatMap(fontOptions.map((option) => option.fonts))
  const fontCss = _.get(
    _.find<any>(fonts, { fontFamily: cleanFontName }),
    'cssFontFamily',
    cleanFontName,
  )
  return fontCss.replace(/"/g, `'`)
}
