import { FormsFieldPreset } from '@wix/forms-common'
import { ExtendApi } from './extend-api'
import translations from '../../../utils/translations'
import CoreApi from '../core-api'
import { EVENTS } from '../../../constants/bi'
import { ACTION_TYPE } from './action-types'
import { CUSTOM_ACTION_CLICKED } from './event-types'

const GetSubscribersApi = (coreApi: CoreApi): ExtendApi => {
  const getNewFields = () => {
    return {
      remove: [FormsFieldPreset.GENERAL_SUBSCRIBE],
    }
  }

  const onCustomActionClicked = (eventPayload) => {
    const { action } = eventPayload

    switch (action.panelName) {
      case 'manageSubscribersPanel':
        coreApi.log({
          evid: EVENTS.PANELS.manageSubscribersPanel.OPEN_PANEL,
          action: 'Manage Subscribers',
        })
        break
    }
  }

  const onEvent = ({ eventType, eventPayload }) => {
    switch (eventType) {
      case CUSTOM_ACTION_CLICKED:
        onCustomActionClicked(eventPayload)
        break
    }
  }

  return {
    onEvent,
    'fields.getNewFields': getNewFields,
    appConfig: () => ({
      features: {
        doubleOptIn: {
          withoutOptInField: true
        }
      },
      mainCTA: {
        description: translations.t('formSettings.mainTab.getSubscribers.mainActionContent'),
        actions: [
          {
            type: ACTION_TYPE.OPEN_MODAL_PANEL,
            panelName: 'manageSubscribersPanel',
            text: translations.t('formSettings.mainTab.getSubscribers.mainActionButtonText'),
          },
        ],
      },
    }),
  }
}

export default GetSubscribersApi
