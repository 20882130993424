import { GFPP, GFPP_ACTIONS, GFPP_IDS } from '../../manifests/manifest-commons'
import translations from '../../../../utils/translations'
import { ROLE_FORM } from '../../../../constants/roles'

export const fieldManifest = ({ keepMainAction2 = false, registration = false, duplicatable = false }) => {
  const dynamicFieldSettingsAction = {
    actionId: GFPP_IDS.DYNAMIC_FIELD_SETTINGS,
    label: translations.t('fieldSettings.gfppTitle'),
  }
  const getMainAction1 = () => (!keepMainAction2 ? dynamicFieldSettingsAction : GFPP.KEEP_DEFAULT)
  const getMainAction2 = () => (keepMainAction2 ? dynamicFieldSettingsAction : GFPP.REMOVE)

  const res: any = {
    relatedToRole: ROLE_FORM,
    displayName: translations.t('fields.labelName'),
    behavior: { duplicatable },
    gfpp: {
      desktop: {
        mainAction1: getMainAction1(),
        mainAction2: getMainAction2(),
        iconButtons: {
          [GFPP_ACTIONS.SETTINGS]: GFPP.REMOVE,
          [GFPP_ACTIONS.LAYOUT]: GFPP.KEEP_DEFAULT,
          [GFPP_ACTIONS.ANIMATION]: GFPP.KEEP_DEFAULT,
          [GFPP_ACTIONS.CONNECT]: GFPP.REMOVE,
          [GFPP_ACTIONS.CUSTOM_CONNECT]: {
            actionId: GFPP_IDS.CONNECT_FIELD,
          },
        },
        helpId: registration ? GFPP.HELP_ID.REGISTRATION_FIELD : GFPP.HELP_ID.FIELD,
      },
      mobile: {
        /* use default configurations */
        helpId: GFPP.HELP_ID.FIELD_MOBILE,
      },
    },
  }
  return res
}