import _ from 'lodash'
import {
  ROLE_DOWNLOAD_MESSAGE,
  ROLE_MESSAGE,
  ROLE_SUBMIT_BUTTON,
  FIELDS,
  THANK_YOU_STEP_ROLE,
  ROLE_TITLE,
  ROLE_SUBTITLE,
  ROLE_LIMIT_MESSAGE,
} from '../../../constants/roles'
import { getFieldPreset, getFormPreset } from '../preset/preset-service'
import { getFieldStyle, commonStyles } from './form-style-service'
import translations from '../../../utils/translations'
import { escapeRegExp, innerText } from '../../../utils/utils'
import { FormPlugin } from '@wix/forms-common'
import { FormPreset } from '../../../constants/form-types'
import { createFieldWithMostCommonLayout } from './layout-service'
import { ResponsiveLayout, Layout } from '@wix/platform-editor-sdk'
import { getPrimaryConnectionFromStructure, isInputField } from '../utils'
import { createStackItemLayout, findNewFieldStackOrder } from '../layout-panel/utils'
import { FieldExtraData } from '../preset/fields/field-types-data'

interface FetchHiddenMessageParams {
  newMessage?
  formLayout
  preset
  locale
  fallbackSchema
  role?
  orderInStack?
}

const getComponentFromPreset = (ravenInstance) => async (
  { role, preset, locale },
  onFailedPresetCallback,
) => {
  if (!preset) return
  const rawPreset = await fetchPreset(ravenInstance)(preset, locale, onFailedPresetCallback)
  if (!rawPreset) return
  const components = rawPreset.components
  const roleSchema = _recursiveFindComponentSchema(components, role)
  return roleSchema
}

const _recursiveFindComponentSchema = (components, role) => {
  for (let i = 0; i < components.length; i++) {
    const comp = components[i]
    if (comp.role == role) {
      return comp
    } else if (comp.components) {
      const subcomp = _recursiveFindComponentSchema(comp.components, role)
      if (subcomp) {
        return subcomp
      }
    }
  }
}

const _constructComponentWithoutRoles = (component: RawComponentStructure, roles: string[]) => {
  const shouldRemoveComponent = !component || roles.includes(component.role)
  if (shouldRemoveComponent) {
    return null
  } else {
    return component.components
      ? {
          ...component,
          components: component.components
            .map((component) => _constructComponentWithoutRoles(component, roles))
            .filter((c) => c),
        }
      : component
  }
}

const convertToInnerStructure = ({ role, connectionConfig, ...rest }: any) => ({
  role,
  connectionConfig,
  data: rest,
})

export const getExtraMessageText = ({ data, presetKey = '', newMessage }) => {
  const parsedMessage = innerText(data.text)
  return {
    text: data.text
      .replace(/\s*<br\/>\s*/g, '') // remove inner tags to let regex match
      .replace(
        new RegExp(`>${escapeRegExp(innerText(data.text))}`),
        `>${
          newMessage ||
          (presetKey === FormPreset.REGISTRATION_FORM
            ? translations.t('settings.errorMessage.registrationForm')
            : parsedMessage)
        }`,
      ),
  }
}

const getChildComponents = (presetKey, comp, newMessage?) =>
  comp.components &&
  comp.components.map((childComp) =>
    deConstructComponent({ presetKey, rawComp: childComp, newMessage }),
  )

const deConstructComponent = ({ presetKey, rawComp, newMessage = null }: formComponent) => {
  const comp = rawComp
  comp.connectionConfig = _.merge({}, comp.config, comp.connectionConfig)
  if (_.includes([ROLE_MESSAGE, ROLE_DOWNLOAD_MESSAGE, ROLE_LIMIT_MESSAGE], comp.role)) {
    comp.data = _.merge(
      {},
      comp.data,
      getExtraMessageText({ data: comp.data, presetKey, newMessage }),
    )
  }
  comp.components = getChildComponents(presetKey, comp, newMessage)
  return comp
}

export const fetchPreset = (ravenInstance) => async (
  presetKey: FormPresetName,
  locale: string = 'en',
  onFailedPresetCallback: Function = _.noop,
): Promise<RawComponentStructure | undefined> => {
  let rawPreset
  try {
    rawPreset = await getFormPreset(ravenInstance)(presetKey, locale)
  } catch (e) {
    await onFailedPresetCallback(`${presetKey}: ${(<Error>e).message}`)
    return
  }
  return rawPreset
}

// return { role, connectionConfig, data }
export const createField = ({
  preset,
  fieldType,
  extraData,
  commonStyles,
  fieldsData,
  formWidth,
  isResponsive,
  layout,
  plugins,
}: {
  preset: string
  fieldType: FieldPreset
  extraData: FieldExtraData
  commonStyles: commonStyles
  fieldsData: FormField[] | undefined
  formWidth?: number
  isResponsive?: boolean
  layout?: any | ResponsiveLayout
  plugins: FormPlugin[]
}) => {
  // TODO remove presetKey
  const stackOrder = isResponsive ? findNewFieldStackOrder(fieldsData) : 0

  const rawPreset = getFieldPreset({
    fieldType,
    extraData,
    plugins,
    isResponsive,
    stackOrder,
  })

  let fieldComponent

  if (isResponsive) {
    fieldComponent = _.merge({}, deConstructComponent({ presetKey: preset, rawComp: rawPreset }), {
      layoutResponsive: { ...layout },
    })
  } else {
    const width = Math.min(formWidth - layout.x, layout.width || rawPreset.layout.width)
    fieldComponent = _.merge({}, deConstructComponent({ presetKey: preset, rawComp: rawPreset }), {
      layout: { ...layout, width },
    })
  }

  const fieldStyle = getFieldStyle(commonStyles, fieldType)
  if (isResponsive) {
    _.assign(fieldComponent.style.stylesInBreakpoints[0].style.properties, fieldStyle)
  } else {
    _.assign(fieldComponent.style.style.properties, fieldStyle)
  }

  return fieldsData
    ? convertToInnerStructure(
        createFieldWithMostCommonLayout(fieldType, fieldsData, fieldComponent, isResponsive),
      )
    : convertToInnerStructure(fieldComponent)
}

const restoreFieldSchema = (ravenInstance) => async (
  { role, preset, locale, fallbackSchema },
  onFailedCallback,
): Promise<{ rawSchema; fallback }> => {
  const rawSchema = await getComponentFromPreset(ravenInstance)(
    {
      role: role,
      preset,
      locale,
    },
    onFailedCallback,
  )

  if (rawSchema) {
    return { rawSchema, fallback: false }
  } else {
    return { rawSchema: fallbackSchema, fallback: true }
  }
}

export const fetchThankYouStepSchema = (ravenInstance) => async ({ preset, locale }) => {
  const { rawSchema } = await restoreFieldSchema(ravenInstance)(
    { role: THANK_YOU_STEP_ROLE, preset, locale, fallbackSchema: {} },
    (reason) => this.coreApi.logFetchPresetsFailed(null, reason),
  )

  return _constructComponentWithoutRoles(rawSchema, [ROLE_MESSAGE, ROLE_DOWNLOAD_MESSAGE])
}

export const fetchSubmitButtonSchema = (ravenInstance) => async (
  { label, preset, locale, fallbackSchema, orderInStack = null },
  onFailedCallback,
) => {
  const { rawSchema, fallback } = await restoreFieldSchema(ravenInstance)(
    { role: ROLE_SUBMIT_BUTTON, preset, locale, fallbackSchema },
    onFailedCallback,
  )

  let extraData = fallback
    ? {
        data: {
          label,
        },
      }
    : {}

  if (orderInStack) {
    extraData = _.merge({}, extraData, { layoutResponsive: createStackItemLayout(orderInStack) })
  }

  const buttonComponent = _.merge({}, deConstructComponent({ rawComp: rawSchema }), extraData)

  return convertToInnerStructure(buttonComponent)
}

export const fetchMultiStepNavigationButtonSchema = (ravenInstance) => async (
  { label, preset, locale, fallbackSchema, role },
  onFailedCallback,
) => {
  const { rawSchema, fallback } = await restoreFieldSchema(ravenInstance)(
    { role, preset, locale, fallbackSchema },
    onFailedCallback,
  )

  const extraData = fallback
    ? {
        data: {
          label,
        },
      }
    : {}

  const buttonComponent = _.merge({}, deConstructComponent({ rawComp: rawSchema }), extraData)

  return convertToInnerStructure(buttonComponent)
}

export const fetchLoginLinkSchema = (ravenInstance) => async (
  { label, preset, locale, fallbackSchema },
  onFailedCallback,
) => {
  const { rawSchema, fallback } = await restoreFieldSchema(ravenInstance)(
    {
      role: FIELDS.ROLE_FIELD_REGISTRATION_FORM_LINK_TO_LOGIN_DIALOG,
      preset,
      locale,
      fallbackSchema,
    },
    onFailedCallback,
  )

  const extraData = fallback
    ? {
        data: {
          label,
        },
      }
    : {}

  const loginLinkComponent = _.merge({}, deConstructComponent({ rawComp: rawSchema }), extraData)

  return convertToInnerStructure(loginLinkComponent)
}

export const fetchHiddenMessage = (ravenInstance) => async (
  {
    newMessage,
    formLayout,
    preset,
    locale,
    fallbackSchema,
    role = ROLE_SUBMIT_BUTTON,
    orderInStack,
  }: FetchHiddenMessageParams,
  onFailedCallback,
) => {
  const { rawSchema } = await restoreFieldSchema(ravenInstance)(
    { role, preset, locale, fallbackSchema },
    onFailedCallback,
  )

  return getMessageSchema({
    rawSchema,
    orderInStack,
    newMessage,
    formLayout,
    role,
  })
}

export const fetchHiddenMessageAndReplaceRole = (ravenInstance) => async (
  fetchHiddenSchemaProps: FetchHiddenMessageParams,
  onFailedCallback,
  replaceByRole: string,
) => {
  const hiddenMessage = await fetchHiddenMessage(ravenInstance)(
    fetchHiddenSchemaProps,
    onFailedCallback,
  )

  return {
    ...hiddenMessage,
    role: replaceByRole,
  }
}

export const getMessageSchema = async ({
  rawSchema,
  newMessage,
  formLayout,
  role,
  orderInStack,
}: {
  rawSchema
  newMessage?
  formLayout
  role
  orderInStack?
}) => {
  const isCenterAligned =
    rawSchema.data.text.match(/text-align:[\s]*center/) && rawSchema.layout.x === 0
  const messageWidth = isCenterAligned ? formLayout.width : rawSchema.layout.width

  const layout: { layout: any } | { layoutResponsive: ResponsiveLayout } = orderInStack
    ? { layoutResponsive: createStackItemLayout(orderInStack) }
    : { layout: { width: messageWidth } }
  const hiddenMessageComponent = _.merge(
    {},
    deConstructComponent({
      rawComp: { ...rawSchema, role },
      newMessage,
    }),
    layout,
  )

  return convertToInnerStructure(hiddenMessageComponent)
}

const createWidgetResponsiveLayout = (
  parentLayoutResponsive: Partial<ResponsiveLayout>,
): ResponsiveLayout => {
  const defaultComponentLayouts = [
    {
      type: 'ComponentLayout',
      height: {
        type: 'auto',
      },
      width: {
        type: 'percentage',
        value: 30,
      },
      minWidth: {
        type: 'px',
        value: 320,
      },
      hidden: false,
      breakpoint: undefined,
    },
  ]

  const componentLayouts = _.get(
    parentLayoutResponsive,
    'componentLayouts',
    defaultComponentLayouts,
  ) as ResponsiveLayout['componentLayouts']

  const layoutResponsive: ResponsiveLayout = {
    id: '',
    type: 'LayoutData',
    itemLayouts: [
      {
        type: 'GridItemLayout',
        gridArea: {
          rowStart: 1,
          columnStart: 1,
          rowEnd: 2,
          columnEnd: 2,
        },
        margins: {
          left: {
            type: 'px',
            value: 0,
          },
          right: {
            type: 'px',
            value: 0,
          },
          top: {
            type: 'px',
            value: 0,
          },
          bottom: {
            type: 'px',
            value: 0,
          },
        },
        alignSelf: 'start',
        justifySelf: 'center',
        breakpoint: undefined,
      },
    ],
    componentLayouts,
    containerLayouts: [
      {
        type: 'GridContainerLayout',
        rows: [
          {
            value: 1,
            type: 'fr',
          },
        ],
        columns: [
          {
            type: 'fr',
            value: 1,
          },
        ],
        breakpoint: undefined,
      },
    ],
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'c1dmp',
    },
  }

  return layoutResponsive
}

export const convertPreset = (
  structureWithOptionalExtraPayload: RawComponentStructure,
  { controllerId, coords = {}, appWidgetStructure = null, width = null, space = null },
): ComponentStructure => {
  const parentLayoutResponsive = _.get(structureWithOptionalExtraPayload, 'parentLayoutResponsive')
  const structure = _.omit(structureWithOptionalExtraPayload, [
    'parentLayoutResponsive',
  ]) as RawComponentStructure
  const formAfterWidthChange = width ? changeFormWidth(structure, width, space) : structure
  const presetLayout = _.merge({}, formAfterWidthChange.layout, coords)
  const presetLayoutResponsive = structure.layoutResponsive
    ? {
        layoutResponsive:
          appWidgetStructure && appWidgetStructure.layoutResponsive
            ? appWidgetStructure.layoutResponsive
            : createWidgetResponsiveLayout(parentLayoutResponsive),
      }
    : {}
  const mobileLayout = _.get(structure.mobileStructure, 'layout')
  const rootComponent = connectComponents(
    {
      ...formAfterWidthChange,
      layout: appWidgetStructure ? _.merge({}, presetLayout, { x: 0, y: 0 }) : presetLayout,
      behaviors: appWidgetStructure ? undefined : structure.behaviors,
      ...(mobileLayout
        ? {
            mobileStructure: {
              layout: appWidgetStructure ? { ...mobileLayout, x: 0, y: 0 } : mobileLayout,
            },
          }
        : {}),
    },
    controllerId,
  )

  return appWidgetStructure
    ? {
        ...appWidgetStructure,
        style: 'appWidget1',
        components: [rootComponent],
        data: { ...appWidgetStructure.data, id: controllerId },
        layout: presetLayout,
        ...presetLayoutResponsive,
        ...(structure.behaviors ? { behaviors: structure.behaviors } : {}),
        ...(_.get(structure.mobileHints, 'hidden')
          ? { mobileHints: { hidden: true, type: 'MobileHints' } }
          : {}),
        ...(mobileLayout
          ? {
              mobileStructure: {
                layout: mobileLayout,
              },
            }
          : {}),
      }
    : rootComponent
}

const connectComponents = (componentStructure: RawComponentStructure, controllerId: string) => {
  const convertedComponent = connectComponent(componentStructure, controllerId)

  if (!convertedComponent.components) {
    return convertedComponent
  }

  return {
    ...convertedComponent,
    components: convertedComponent.components.map((c) => connectComponents(c, controllerId)),
  }
}

export const connectComponent = (
  componentStructure: RawComponentStructure,
  controllerId: string,
) => ({
  ..._.omit(componentStructure, ['role', 'config']),
  connections: {
    type: 'ConnectionList',
    items: [
      {
        type: 'ConnectionItem',
        role: componentStructure.role,
        isPrimary: true,
        config: JSON.stringify(componentStructure.config),
        controllerId,
      },
    ],
    metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
  },
})

export const enhanceConfigByRole = async (
  structure: RawComponentStructure,
  produceConfigMap: { [key: string]: (config) => Promise<ComponentConfig> },
): Promise<RawComponentStructure> => {
  const scanStructure = async (componentStructure) => {
    const produceConfig = produceConfigMap[componentStructure.role] || _.identity
    return {
      ...componentStructure,
      config: await produceConfig(componentStructure.config),
      ...(componentStructure.components && {
        components: await Promise.all(
          componentStructure.components.map((component) =>
            enhanceConfigByRole(component, produceConfigMap),
          ),
        ),
      }),
    }
  }

  return scanStructure(structure)
}

export const enhanceStructreWithSnapshot = (structure, formSnapshot: FormSnapshot) => {
  const formStructure = _.merge({}, structure, {
    config: formSnapshot.formComponent.config,
    layout: formSnapshot.formComponent.layout,
  })
  formStructure.components = formSnapshot.components.map((component) =>
    _.omit(component, 'componentRef'),
  )
  return formStructure
}

export const getFormControllerType = (structure: RawComponentStructure): ControllerType => {
  const plugins = structure.config.plugins
  return _.find(plugins, { id: FormPlugin.MULTI_STEP_FORM })
    ? 'multiStepForm'
    : _.find(plugins, { id: FormPlugin.GET_SUBSCRIBERS })
    ? 'getSubscribers'
    : 'wixForms'
}

export const getComponentByRole = (
  structure: ComponentStructure,
  role: string,
): ComponentStructure | undefined => {
  const primaryConnection = getPrimaryConnectionFromStructure(structure)
  if (primaryConnection && primaryConnection.role === role) {
    return structure
  }
  if (!structure.components) {
    return
  }
  return _.first(
    structure.components.map((component) => getComponentByRole(component, role)).filter((c) => c),
  )
}

export const connectComponentToConnection = (
  component: ComponentStructure,
  { role, config, controllerId },
): ComponentStructure => ({
  ...component,
  connections: {
    metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
    type: 'ConnectionList',
    items: [
      {
        type: 'ConnectionItem',
        role,
        config: JSON.stringify(config),
        isPrimary: true,
        controllerId,
      },
    ],
  },
})

export const limitComponentInContainer = (component: ComponentStructure, containerHeight: number) =>
  component &&
  _.merge({}, component, {
    layout: {
      y: Math.max(Math.min(component.layout.y, containerHeight - component.layout.height - 10), 0),
    },
  })

type LayoutUpdate = { layout: Partial<Layout>; id: number; role?: string }
const calcFormWidthDesktop = ({
  inputFields,
  submitButton,
  successMessage,
  titles,
  form,
  spacing,
  width,
}: {
  inputFields: LayoutUpdate[]
  titles: LayoutUpdate[]
  submitButton: LayoutUpdate
  successMessage: LayoutUpdate
  form: LayoutUpdate
  spacing: number
  width: number
}): LayoutUpdate[] => {
  if (!inputFields.length) {
    return []
  }
  const elements = [...titles, ...inputFields, submitButton, successMessage].filter(
    (element) => element,
  )

  const fieldsUpdates: LayoutUpdate[] = []
  const isFieldsSharingTheRow = (field: LayoutUpdate, index: number) =>
    index > 0 && field.layout.y === inputFields[index - 1].layout.y
  const rows = inputFields.reduce<LayoutUpdate[][]>((acc, curr, index) => {
    if (isFieldsSharingTheRow(curr, index)) {
      acc[acc.length - 1] = acc[acc.length - 1].concat(curr)
    } else {
      acc = acc.concat([[curr]])
    }
    return acc
  }, [])
  const isSubmitButtonSharingRow = _.get(rows, '[0][0].y') === submitButton.layout.y
  const updateFormKeepColumns = (rowsToReduce: LayoutUpdate[][]) =>
    rowsToReduce.reduce((prevY, row, idx) => {
      const firstElement = row[0]
      const buttonInRow = isSubmitButtonSharingRow && idx === 0
      const fieldsInRow = buttonInRow ? row.length + 1 : row.length
      const widthForFields = width - spacing * (fieldsInRow - 1)
      const elementWidth = buttonInRow
        ? widthForFields / (fieldsInRow - 0.75)
        : widthForFields / fieldsInRow

      const newY = prevY >= 0 ? prevY + spacing : firstElement.layout.y
      row.reduce((prevX, field) => {
        const newX = prevX >= 0 ? prevX + spacing + elementWidth : field.layout.x
        fieldsUpdates.push({
          id: field.id,
          layout: { width: elementWidth, x: newX, y: newY },
        })
        return newX
      }, -1)

      return newY + (firstElement.layout.height || 0)
    }, -1)

  titles.forEach((element) => {
    if (element) {
      fieldsUpdates.push({
        id: element.id,
        layout: { width },
      })
    }
  })

  const lastY = updateFormKeepColumns(rows)
  const lastElement = rows[rows.length - 1][0]
  const heightDiff = lastElement.layout.y + lastElement.layout.height - lastY
  const buttonNewY = submitButton.layout.y - heightDiff

  if (isSubmitButtonSharingRow) {
    const fieldInFirstRow = fieldsUpdates.find((field) => field.id === _.last(rows[0])?.id)
    fieldsUpdates.push({
      id: submitButton.id,
      layout: {
        width: (fieldInFirstRow.layout.width || 0) / 4,
        x: (fieldInFirstRow.layout.x || 0) + (fieldInFirstRow.layout.width || 0) + spacing,
        y: fieldInFirstRow.layout.y || 0,
      },
    })
  } else if (submitButton.layout.width === form.layout.width) {
    fieldsUpdates.push({
      id: submitButton.id,
      layout: { width, y: buttonNewY, x: lastElement.layout.x },
    })
  } else {
    const calcXDecrease = (elmX: number, elmWidth: number) => {
      const widthDiff = form.layout.width - width
      const leftSpace = elmX - lastElement.layout.x
      const sidesRadio = leftSpace / (form.layout.width - elmWidth)
      return widthDiff * sidesRadio
    }
    fieldsUpdates.push({
      id: submitButton.id,
      layout: {
        x: submitButton.layout.x - calcXDecrease(submitButton.layout.x, submitButton.layout.width),
        y: buttonNewY,
      },
    })
  }

  if (successMessage) {
    const messageNewY = successMessage.layout.y - heightDiff
    const textNewWidth = width * (successMessage.layout.width / form.layout.width)
    fieldsUpdates.push({
      id: successMessage.id,
      layout: { width: textNewWidth, y: messageNewY },
    })
  }

  const lastField = fieldsUpdates[fieldsUpdates.length - 1]
  const originalField = elements.find((element) => element.id === lastField.id)
  const height = (lastField.layout.y || 0) + (originalField.layout.height || 0) + spacing

  const formUpdate: LayoutUpdate = {
    id: form.id,
    layout: { width, height },
  }

  return [formUpdate, ...fieldsUpdates]
}

// this function will work only with naive form structure
const changeFormWidth = (
  formStructure: RawComponentStructure,
  width: number,
  spacing: number,
): RawComponentStructure => {
  const toLayoutUpdate = (component: RawComponentStructure, index: number): LayoutUpdate => ({
    layout: component.layout,
    role: component.role,
    id: index,
  })
  const formComponents = formStructure.components.map(toLayoutUpdate)
  const submitButton = _.find(formComponents, { role: ROLE_SUBMIT_BUTTON })
  const successMessage = _.find(formComponents, { role: ROLE_MESSAGE })
  const inputFields = formComponents.filter((component) => isInputField(component.role))
  const titles = formComponents.filter(
    (component) => component.role === ROLE_TITLE || component.role === ROLE_SUBTITLE,
  )

  const [formUpdate, ...componentsUpdated] = calcFormWidthDesktop({
    inputFields: _.sortBy(inputFields, [(c) => c.layout.y, (c) => c.layout.x]),
    submitButton,
    successMessage,
    titles,
    form: toLayoutUpdate(formStructure, -1),
    width,
    spacing,
  })

  const components = formStructure.components.map((comp, idx) => ({
    ...comp,
    layout: { ...comp.layout, ..._.find(componentsUpdated, (update) => update.id === idx).layout },
  }))

  return {
    ...formStructure,
    layout: { ...formStructure.layout, ...formUpdate.layout },
    components,
  }
}
