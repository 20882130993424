import _ from 'lodash'
import { FormPlugin, LimitTypes } from '@wix/forms-common'

const NativePlugins = [
  FormPlugin.LIMIT_FORM_SUBMISSONS,
  FormPlugin.FORM_BUILDER
]

export const getActivePlugin = (plugins: FormPlugin[]): FormPlugin => {
  return _.first(plugins) || FormPlugin.FORM_BUILDER
}

export const getPlugins = (componentConnection: ComponentConnection): ComponentPlugin[] =>
  _.get(componentConnection, 'config.plugins', [])

export const convertPluginsToFormsPlugins = (plugins: ComponentPlugin[] = []): FormPlugin[] =>
  _.map(plugins, 'id')

export const findPlugin = (plugins: ComponentPlugin[], pluginId: FormPlugin) =>
  _.find(plugins, plugin => plugin.id === pluginId)


export const getNonNativePlugins = (plugins: ComponentPlugin[]) =>
  _.filter(plugins, plugin => !_.includes(NativePlugins, plugin.id))

export const isNativeForm = (plugins: ComponentPlugin[]) => {
  const nonNativePlugins = getNonNativePlugins(plugins)
  return _.isEmpty(nonNativePlugins)
}

export const updatePlugin = (plugins: ComponentPlugin[], newPlugin: ComponentPlugin) => {
  const updatedPlugins = _.filter(plugins, plugin => plugin.id !== newPlugin.id)
  return [...updatedPlugins, newPlugin]
}

export const removePlugin = (plugins: ComponentPlugin[], pluginId: FormPlugin) => {
  return _.filter(plugins, plugin => plugin.id !== pluginId)
}

export const getActivePluginFromComponentConnection = (
  componentConnection: ComponentConnection,
): FormPlugin => getActivePlugin(convertPluginsToFormsPlugins(getPlugins(componentConnection)))

export const getFormPlugins = (componentConnection: ComponentConnection) =>
  convertPluginsToFormsPlugins(getPlugins(componentConnection))

export const isMultiStepPlugin = (plugins: FormPlugin[]) =>
  _.includes(plugins, FormPlugin.MULTI_STEP_FORM)
export const isRegistrationFormPlugin = (plugins: FormPlugin[]) =>
  _.includes(plugins, FormPlugin.REGISTRATION_FORM)

const counterRuleCreator = (value) => ({
  submissions: {
    limit: Number(value),
    timeFrame: 'TOTAL',
  },
})

const dateRuleCreator = (value) => ({
  date: {
    date: value.date,
    operator: 'BEFORE',
  },
})

const createRuleMapping = {
  [LimitTypes.AMOUNT]: counterRuleCreator,
  [LimitTypes.TIME]: dateRuleCreator,
}

export const convertToV2SubmissionLimitStructure = (plugin) => {
  const type = _.get(plugin, 'payload.limitType')
  const value = _.get(plugin, 'payload.limitValue')

  const ruleCreator = createRuleMapping[type]

  return {
    [_.camelCase(plugin.id)]: {
      [_.camelCase(plugin.id)]: _.compact([ruleCreator(value)]),
    },
  }
}