import { getFieldRenderConfigFields } from '../preset/fields/field-types-data'
import { FormPlugin, FieldRenderConfigType } from '@wix/forms-common'
import { CUSTOM_FIELD, CRM_TAGS } from '../../../constants/crm-types-tags'
import { SyncField, SyncData } from '../../../panels/contact-sync-panel/constants/types'
import _ from 'lodash'
import { CustomFieldResponse } from '../../../constants/field-types'
import { isCrmTypeAllowedOnce } from '../../../panels/contact-sync-panel/utils'
import { fieldsStore } from '../preset/fields/fields-store'

export const isFieldSyncable = (plugin: FormPlugin, field: FormField) => {
  const fieldConfig = getFieldRenderConfigFields([plugin], field.fieldType)
  return fieldConfig.crmSync === FieldRenderConfigType.ENABLED
}

export const getSyncedFieldsCount = (
  fields: { crmType: string; customFieldId: string }[],
): number => fields.filter(field => field.crmType !== CUSTOM_FIELD || !!field.customFieldId).length

const getSyncDataFromField = (
  { crmType, crmTag, customFieldId, customFieldName }: Partial<FormField>,
  customFields: CustomFieldResponse[],
): SyncData => {
  const fieldCustomFieldExistsInCustomFields: boolean = _.some(customFields, { id: customFieldId })
  return _.defaults(
    _.pickBy(
      {
        crmType,
        crmTag:
          crmType === CUSTOM_FIELD
            ? undefined
            : crmTag || (isCrmTypeAllowedOnce(crmType) ? CRM_TAGS.MAIN : CRM_TAGS.OTHER),
        customFieldId: fieldCustomFieldExistsInCustomFields ? customFieldId : undefined,
        customFieldName: fieldCustomFieldExistsInCustomFields ? customFieldName : undefined,
      },
      prop => prop !== null,
    ),
    {
      crmType: undefined,
      crmTag: undefined,
      customFieldId: undefined,
      customFieldName: undefined,
    },
  )
}

export const createSyncFieldFromFormField = (
  field: Partial<FormField>,
  customFields: CustomFieldResponse[],
): SyncField => {
  const fieldDefinition = fieldsStore.allFieldsData[field.fieldType]
  return {
    componentRef: field.componentRef,
    customFields: fieldDefinition.customFields,
    fieldName: field.crmLabel,
    syncData: getSyncDataFromField(field, customFields),
    fieldType: field.fieldType,
  }
}

export const createSyncFieldsFromFormFields = (
  fieldsOnStage: FormField[],
  plugin: FormPlugin,
  customFields: CustomFieldResponse[],
): SyncField[] =>
  fieldsOnStage
    .filter(field => isFieldSyncable(plugin, field))
    .map(field => createSyncFieldFromFormField(field, customFields))
