export const FORMS_SERVER = {
  URL: '/_api/wix-form-builder-web/v1',
  ENDPOINT: {
    EMAILS: {
      ADD: 'emails/add',
      GET: 'emails/get',
      SITE_USERS: 'emails/site-users',
    },
    PUBLISH_SITE: 'publish-site',
    EDIT_DRAFT: 'edit-draft',
    RESTRICTIONS: 'premium/restrictions',
    CONVERT_CONTACT_FORM_EMAIL: 'convert-contact-form-email',
    ADD_FORM_ID: 'contact-form-migration/add-form-id-mapping',
  },
}

export const FORMS_SERVERLESS = {
  URL: '/_serverless/forms-editor-service',
  ENDPOINT: {
    USERS: 'users',
    RESTRICTIONS: 'restrictions',
  },
}

export const FEATURES_MANAGER_URL = '/_api/premium-features-manager/v1'
export const PLATFORMIZED_PAYMENTS_URL = '/_api/payment-services-web/payments/v2'
export const PREMIUM_STORE_URL = '/_api/store/v1'
