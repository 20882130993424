import { IGeneralField } from '../general-field'
import { FirstName } from './definitions/first-name'
import { LastName } from './definitions/last-name'
import { Company } from './definitions/company'
import { Position } from './definitions/position'
import { Email } from './definitions/email'
import { Address } from './definitions/address'
import { Phone } from './definitions/phone'
import { Birthday } from './definitions/birthady'
import { Anniversary } from './definitions/anniversary'
import { Website } from './definitions/webstie'
import { AgreeTerms } from './definitions/agree-terms'
import { GeneralRecaptcha } from '../general-fields/definitions/general-recaptcha'

export const createRecommendedFields = (): IGeneralField[] => [
  new FirstName(),
  new LastName(),
  new GeneralRecaptcha(),
  new Company(),
  new Position(),
  new Email(),
  new Address(),
  new Phone(),
  new Birthday(),
  new Anniversary(),
  new Website(),
  new AgreeTerms(),
]
