import { getEditorSentryDSN, getAppVersion, serializeError, isProduction } from '../../utils/utils'
import Raven, { RavenStatic } from 'raven-js'
import _ from 'lodash'
import { EditorReadyOptions } from '@wix/platform-editor-sdk'

let ravenInstance: RavenStatic
let fedopsLogger
let metaSiteId

export const getRavenInstance = () => ravenInstance

export const setRavenInstance = raven => {
  // for test purpose only, to init actual raven instance use only initMonitoring once in initApp
  ravenInstance = raven
}

export const getFedopsLogger = () => fedopsLogger

export const updateMetaSiteId = msid => {
  metaSiteId = msid
}

export const initMonitoring = (monitoring: EditorReadyOptions['monitoring']) => {
  const { createSentryMonitorForApp, createFedopsLogger } = monitoring

  fedopsLogger = createFedopsLogger()('wix-forms-editor-api')
  fedopsLogger.appLoadStarted()

  ravenInstance = createSentryMonitorForApp(getEditorSentryDSN(), {
    dataCallback: () => ({
      tags: {
        ...(metaSiteId ? { metaSiteId } : {}),
        logger: 'api',
        release: getAppVersion(),
      },
      environment: isProduction() ? 'Prod' : 'Dev',
    }),
  })

  return { ravenInstance, fedopsLogger }
}

export const handleError = (err, options = {}) => {
  console.error('handleError', err)

  if (ravenInstance) {
    ravenInstance.captureException(
      err,
      _.merge(
        {
          extra: {
            error: serializeError(err),
          },
        },
        options,
      ),
    )

    ravenInstance.setTagsContext()
    ravenInstance.setExtraContext()
  }
}

export const captureBreadcrumb = (crumb: Raven.Breadcrumb) => {
  if (ravenInstance) {
    ravenInstance.captureBreadcrumb(crumb)
  }
}
