import _ from 'lodash'
import { FIELDS_ROLES } from '../../../constants/roles'
import { getLayout } from './contact-form-layout'

export const enhanceFormWithMobileStructure = (
  contactForm: DynamicContactForm,
  convertedForm: RawComponentStructure,
  { mobileLayouts: layouts },
): RawComponentStructure => {
  const contactFormMobileLayout = _.get(contactForm.mobileStructure, 'layout')
  if (!contactFormMobileLayout) {
    return convertedForm
  }
  return {
    ...convertedForm,
    components: convertedForm.components.map((component, index) => {
      const { layout, inputHeight } = getLayout(
        { skin: contactForm.skin, contactFormWidth: contactFormMobileLayout.width },
        component,
        layouts[index],
      )
      return _.includes(FIELDS_ROLES, component.role)
        ? {
            ...component,
            props: {
              ...component.props,
              inputHeightMobile: inputHeight,
            },
            mobileStructure: {
              layout,
            },
          }
        : { ...component, mobileStructure: { layout } }
    }),
    mobileStructure: { layout: contactFormMobileLayout },
  }
}
